import { EventEmitter, inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Guid } from '../utility';
import { Auth } from '@angular/fire/auth';

interface WebSocketMessage {
  event: string;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private appService = inject(AppService);
  private auth = inject(Auth);
  private socket?: WebSocket;
  private messageBus = new Subject<WebSocketMessage>();
  connected$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.appService.idToken$.subscribe(async (idToken: string | null) => {
      this.disconnect();
      if (idToken) await this.connect();
    });
  }

  clientId = Guid.newGuid();
  reconnect: NodeJS.Timeout | undefined;
  ping: NodeJS.Timeout | undefined;
  async connect() {
    const idToken = await this.auth.currentUser!.getIdToken();
    const url = new URL(environment.app.serviceUrl);
    url.protocol = url.protocol.replace('http', 'ws');

    this.socket = new WebSocket(`${url}connect?cid=${this.clientId}`);
    this.socket.onopen = () => {
      if (this.reconnect) clearTimeout(this.reconnect);
      if (this.ping) clearTimeout(this.ping);
      console.log('Connected to WebSocket server', new Date());
      this.connected$.next(true);
      // Send authentication token
      this.socket?.send(JSON.stringify({ event: 'auth', data: idToken }));
      //send ping to refresh the client
      this.ping = setInterval(() => {
        if (this.socket?.readyState === WebSocket.OPEN)
          this.socket.send(JSON.stringify({ event: 'ping', data: this.clientId }));
      }, 55 * 1000);
    };

    this.socket.onclose = () => {
      if (this.connected$.value) {
        console.log('Disconnected from WebSocket server', new Date());
        this.connected$.next(false);
      }
      this.reconnect = setTimeout(async () => await this.connect(), 5000);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.socket.onmessage = (event) => {
      try {
        // console.log('Message from server:', event, new Date());
        this.messageBus.next(JSON.parse(event.data) as WebSocketMessage);
      } catch (e) {
        console.error('Error parsing message:', e);
      }
    };
  }

  disconnect() {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.close();
      this.socket = undefined;
    }
    if (this.reconnect) clearTimeout(this.reconnect);
    if (this.ping) clearTimeout(this.ping);
  }

  onEvent$<T>(event: string): Observable<T> {
    return this.messageBus.pipe(
      filter(message => message.event === event),
      map(message => message.data as T)
    );
  }

  emit(event: string, data: any) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ event, data }));
    } else {
      console.warn('Cannot emit event, socket not connected');
    }
  }
}
