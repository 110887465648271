import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { filter, firstValueFrom, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { SocketService } from './socket.service';
import { CallFeedback, CallInterface, CallOutcome, callReasonType, CallReportCriteria, CallSearchCriteria, CallStatus, incomingCallPayload } from 'voxr-shared';
import { Call } from '@vapi-ai/web/dist/api';

type createCallPayload = {
  assistantId?: string;
  contactId?: string;
  contact?: any;
  campaignId?: string;
  initiate?: boolean;
  isWebCall?: boolean;
  isTestCall?: boolean;
  callTest?: any;
};

export const CallStatuses: CallStatus[] = ['queued', 'initiated', 'ringing', 'in-progress', 'completed', 'busy', 'failed', 'no-answer', 'ended', 'canceled'];
export const CallReasons: callReasonType[] = ['first-call', 'dropped-call', 'callback', 'incoming-call'];
export const CallOutcomes: string[] = ['TRANSFERRED', 'APPOINTMENT_SET', 'CALLBACK_SET', 'DISQUALIFIED', 'NEEDS_HUMAN', 'NEEDS_REVIEW', 'NOT_INTERESTED', 'DROPPED_CALL', 'BAD_NUMBER', 'DO_NOT_CALL', 'VOICEMAIL', 'NO_ANSWER'];


@Injectable({
  providedIn: 'root'
})
export class CallService {
  private http = inject(HttpClient);
  private socketService = inject(SocketService);

  private _incomingCall$ = new Subject<incomingCallPayload>();
  incomingCall$ = this._incomingCall$.asObservable();

  constructor() {
    this.socketService.connected$.pipe(filter((connected: boolean) => connected)).subscribe(() => {
      this.socketService.onEvent$<incomingCallPayload>('incoming-call').subscribe(payload => this._incomingCall$.next(payload));
    });
  }

  createCall(payload: createCallPayload) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/create-call`, payload));
  }

  endCall(callId: string) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/end-call`, { callId }));
  }

  sendMessage(callId: string, payload: any) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/send-message`, { callId, payload }));
  }

  lastContactsCalls(criteria: CallSearchCriteria) {
    return firstValueFrom(this.http.post<{ contacts: { call: CallInterface, total: number }[], total: number }>(`${environment.app.serviceUrl}/call/search/contacts`, { criteria }));
  }

  search(criteria: CallSearchCriteria, adminMode = false) {
    let params = {} as any;
    if (adminMode) params.admin = true;
    return this.http.post<{ list: CallInterface[], total: number }>(`${environment.app.serviceUrl}/call/search`, { criteria }, { params });
  }

  mine(criteria: CallSearchCriteria) {
    return this.http.post<{ list: CallInterface[], total: number }>(`${environment.app.serviceUrl}/call/mine`, { criteria });
  }

  saveFeedback(callId: string, feedback: CallFeedback) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/save-feedback`, { callId, feedback }));
  }

  manualCallOutcome(callId: string, outcome: CallOutcome | string, data?: string) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/manual-outcome`, { callId, outcome, data }));
  }

  resetContact(campaignId: string, contactId: string) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/reset-contact`, { campaignId, contactId }));
  }

  getCall(callId: string): any {
    return firstValueFrom(this.http.get<any>(`${environment.app.serviceUrl}/call/get/${callId}`));
  }

  updateWebCall(callId: string, vapiCall: Call, callEnded = false) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/update-web-call`, { callId, vapiCall, callEnded }));
  }

  rejectTransfer(callId: string, conferenceName: string) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/reject-transfer`, { callId, conferenceName }));
  }

  initiateCallsDebug() {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/job/calls/initiate`, {}));
  }

  createCallsDebug() {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/job/calls/create`, {}));
  }

  adminGetQueuedCalls() {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/admin/queued`, {}));
  }

  detectOutcome(callId: string) {
    return firstValueFrom(this.http.post<CallInterface>(`${environment.app.serviceUrl}/call/outcome/${callId}`, {}));
  }

  initiateCall(callId: string) {
    return firstValueFrom(this.http.post<CallInterface>(`${environment.app.serviceUrl}/call/init/${callId}`, {}));
  }

  cancelCall(callId: string) {
    return firstValueFrom(this.http.post<CallInterface>(`${environment.app.serviceUrl}/call/cancel/${callId}`, {}));
  }

  getCallReport(criteria: CallReportCriteria) {
    return firstValueFrom(this.http.post<any>(`${environment.app.serviceUrl}/call/report`, { criteria }));
  }

}
